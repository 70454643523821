
















import Editor from '../../../shared/components/Editor/index.vue';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { EditorPluginModel } from '../../../shared/models/editorPluginModel';
import { EditorPluginEnum } from '../../../shared/enums/editorPluginEnum';
import uniqueId from 'lodash/uniqueId';
export default {
  components: { Editor },
  props: {
    institutionCode: { type: String, default: null },
    value: { type: String, default: '' },
    maxLength: { type: Number, default: 0 },
    placeholderTextkey: { type: String, default: '' },
    ariaLabel: { type: String, default: 'ARIA_LABEL_WRITE_CONTENT' },
    disabled: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      editorPluginDetails: types.GET_EDITOR_PLUGIN_DETAILS,
    }),
    uniqueId() {
      return `plugin_editor_${uniqueId()}`;
    },
    key() {
      return `${uniqueId()}-${this.institutionCode}`;
    },
    institution() {
      return this.institutions.find(institution => institution.institutionCode === this.institutionCode);
    },
    plugins() {
      const plugins = [];
      this.editorPluginDetails.forEach((config: EditorPluginModel) => {
        if (
          config.institutionType !== this.institution?.institutionType ||
          config.municipalityCode !== this.institution?.municipalityCode
        ) {
          return;
        }
        switch (config.pluginName.toLowerCase()) {
          case 'skoletube':
            plugins.push(EditorPluginEnum.SKOLETUBE);
            break;
          case 'børnetube':
            plugins.push(EditorPluginEnum.BORNETUBE);
            break;
          case 'youtube':
            plugins.push(EditorPluginEnum.YOUTUBE);
            break;
        }
      });
      return plugins;
    },
  },
};

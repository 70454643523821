<template>
  <div>
    <div id="responseEvent">
      <aula-modal
        ref="modalResponseMeeting"
        :is-scroll-top="false"
        :is-loading="isLoading"
        :show-ok="isShownOkButton"
        :show-edit="isShownEditButton"
        :show-cancel="!isChild"
        header-text="CALENDAR_REGISTRATION_TIMESLOT_EVENT"
        header-icon="icon-Aula_export"
        :ok-text="okTextKey"
        :edit-text="editTextKey"
        :cancel-text="cancelTextKey"
        @cancelClicked="isMobile ? closeMeetingForm() : handleCancelButton()"
        @editClicked="
          canHandleMeeting
            ? editMeetingEvent()
            : showClearTimeSlotModal(timeSlotIndexToDelete, timeSlotToDelete, concerningInstProfileId)
        "
        @okClicked="onOkButtonClicked"
        @closeClicked="closeMeetingForm"
      >
        <template #headline>
          <HeadlineAlert v-if="lastReminderDateTime">
            {{ 'CALENDAR_EVENT_REMINDER_ALERT' | fromTextKey({ dateTime: lastReminderDateTime }) }}
          </HeadlineAlert>
        </template>
        <template v-if="isDataValid">
          <div class="response-header">
            <div class="response-title">
              {{ meetingEvent.title }}
            </div>
            <div class="response-persons">
              <div class="participant">
                <div class="organizer">
                  <div v-if="meetingEvent.creator != null">
                    <span class="title">{{ 'CALENDAR_LABEL_EVENT_CREATOR' | fromTextKey }}: </span>
                    <span> {{ meetingEvent.creator | displayProfileNameWithMetadata }}</span>
                  </div>
                  <div v-if="organizerNames.length > 0">
                    <span class="title">{{ 'CALENDAR_LABEL_EVENT_ORGANIZERS' | fromTextKey }}: </span>
                    <span>{{ organizerNames }}</span>
                  </div>
                </div>
                <AulaAlert v-if="!hasEnoughSlotsForEveryone && canRespondAsInvitee" class="my-2">
                  {{ 'CALENDAR_ALERT_NOT_ENOUGH_SLOTS_FOR_EVERYONE' | fromTextKey }}
                </AulaAlert>
                <CalendarEventInvitees :invitees="eventInvitees" :show-send-reminder="canSendReminder" />
                <template v-if="concerningChild && meetingEvent.type != eventTypes.PERFORMANCE_MEETING">
                  <span class="title">{{ 'CALENDAR_LABEL_EVENT_CONCERNING' | fromTextKey }}: </span>
                  <span>{{ concerningChild }}</span
                  ><br />
                </template>
                <template v-if="meetingEvent.responseDeadline">
                  <span class="title">{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}: </span>
                  <span>{{ moment(meetingEvent.responseDeadline).format('dddd [d.] D[.] MMM[.]') }}</span>
                </template>
              </div>
            </div>
          </div>
          <div
            v-if="meetingEvent.description != null"
            class="response-description"
            v-html="meetingEvent.description.html"
          />
          <attachment-thumbnails :attachments="meetingEvent.attachments" @attachmentClicked="attachmentClicked" />
          <attachment-list :attachments="meetingEvent.attachments" />
          <b-form-radio-group
            v-if="[portalRoles.EMPLOYEE, portalRoles.GUARDIAN, portalRoles.OTP].includes(profile.role)"
            id="radioTimeSlot"
            tabindex="0"
            autofocus
            name="radioTimeSlot"
            :aria-label="'ARIA_LABEL_CHOOSE_MEETING' | fromTextKey"
          >
            <div v-if="meetingEvent.timeSlot != null" class="response-timeslot">
              <div v-for="(timeSlot, i) in meetingEvent.timeSlot.timeSlots" :key="i" class="timeslot">
                <div class="header" aria-hidden="false" role="heading">
                  {{ getTimeslotFullDateTime(timeSlot) }} <br />
                  {{ 'CALENDAR_LABEL_MEETING_LOCATION' | fromTextKey }}:
                  {{ timeSlot.primaryResource ? timeSlot.primaryResource.name : timeSlot.primaryResourceText }}
                </div>
                <template v-for="(section, index) in getTimeslotSections(timeSlot)">
                  <CalendarInviteeMeetingSlot
                    v-if="canRespondAsInvitee"
                    :key="`section-${index}`"
                    :timeslot="section"
                    :invitees="meetingInvitees"
                    :concerning-institution-profile-id="concerningInstProfileId"
                    :number-of-slots="numberOfParticipantsPerTimeslot"
                    :selected="getIsSelectedSlot(timeSlot, index)"
                    class="p-2"
                    @selectSlot="timeSlotSelectedChange(timeSlot, index)"
                    @clearSlot="showClearTimeSlotModal(index, timeSlot, $event)"
                  />
                  <CalendarOrganizerMeetingSlot
                    v-else
                    :key="`section-${index}`"
                    :timeslot="section"
                    :invitees="meetingInvitees"
                    :can-handle-slot="isCreator || isCoOrganizer || isAddedOnlyInSchoolCalendar"
                    :number-of-slots="numberOfParticipantsPerTimeslot"
                    @assignSlot="selectedOnBehalfConfirmModal(index, $event, timeSlot)"
                    @clearSlot="showClearTimeSlotModal(index, timeSlot, $event)"
                  />
                </template>
                <b-alert variant="danger" :show="showMissingTimeAlert">
                  {{ 'CALENDAR_ALERT_TIME' | fromTextKey }}
                </b-alert>
              </div>
            </div>
          </b-form-radio-group>
          <footer v-if="isMobile" class="mobile-footer w-100">
            <AulaButton v-if="canHandleMeeting" variant="link" @click="onPressDelete">
              <i class="icon-Aula_bin" /> {{ 'DELETE' | fromTextKey }}
            </AulaButton>
            <AulaButton
              v-if="canRespondAsInvitee && hasAnswered"
              variant="link"
              @click="showClearTimeSlotModal(timeSlotIndexToDelete, timeSlotToDelete, concerningInstProfileId)"
            >
              {{ 'CALENDAR_TIMESLOT_CANCEL_EVENT' | fromTextKey }}
            </AulaButton>
          </footer>
        </template>
      </aula-modal>
    </div>
    <media-details
      v-if="isOpenMediaDetail"
      :media-id="selectedMediaId"
      :album="getEventAttachments"
      :parent="parentTypes.CALENDAR"
      @onClosed="closeMediaDetail"
    />
    <aula-modal
      ref="deleteModal"
      :is-loading="isLoading"
      @cancelClicked="cancelDeleteMeetingEvent"
      @okClicked="deleteMeetingEvent"
    >
      <template v-if="isHandlingOthersEvent">
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_1' | fromTextKey }}<br />
        {{ 'CALENDAR_DELETE_OTHERS_EVENT_WARNING_2' | fromTextKey }}
      </template>
      <template v-else>
        {{ 'CALENDAR_DELETE_EVENT_TEXT' | fromTextKey }}
      </template>
    </aula-modal>
    <aula-modal
      ref="clearTimeSlotModal"
      ok-text="YES"
      :is-loading="isLoading"
      @cancelClicked="cancelClearTimeSlotModal()"
      @okClicked="clearTimeSlot()"
    >
      {{ 'CALENDAR_CLEAR_BOOKED_TIMESLOT' | fromTextKey }}<br />
      {{ 'CALENDAR_CLEAR_BOOKED_TIMESLOT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="updateTimeSlotModal"
      :is-loading="isLoading"
      ok-text="BUTTON_YES"
      @cancelClicked="cancelUpdateTimeSlotModal()"
      @okClicked="updateTimeSlotResponse()"
    >
      <template v-if="timeSlot != null && selectedTimeSlotIndex != null">
        {{ 'CALENDAR_UPDATE_BOOKED_TIMESLOT' | fromTextKey }}
        {{ moment(timeSlot.timeSlotIndexes[selectedTimeSlotIndex].startTime) | shortDate }}
        {{ 'CALENDAR_UPDATE_BOOKED_TIMESLOT_2' | fromTextKey }}
        {{ moment(timeSlot.timeSlotIndexes[selectedTimeSlotIndex].startTime) | time }}
        -
        {{ moment(timeSlot.timeSlotIndexes[selectedTimeSlotIndex].endTime) | time }}
      </template>
    </aula-modal>
    <aula-modal
      ref="timeSlotAlreadyBooked"
      :show-cancel="false"
      @okClicked="
        $refs.timeSlotAlreadyBooked.hide();
        $refs.modalResponseMeeting.show();
      "
    >
      {{ 'CALENDAR_TIMESLOT_ALREADY_BOOKED' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="selectOnBehalfModal"
      :is-loading="isLoading"
      ok-text="BUTTON_YES"
      @cancelClicked="cancelClickedSelectOnBehalfModal()"
      @okClicked="selectedOnBehalf()"
    >
      {{
        blockingTimeSlot
          ? 'CALENDAR_RESPOND_ON_BEHALF_CONFIRM_BLOCKED'
          : 'CALENDAR_RESPOND_ON_BEHALF_CONFIRM' | fromTextKey
      }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      header-text="CALENDAR_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import AttachmentList from '../../../shared/components/AttachmentList';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import MediaDetails from '../gallery/MediaDetail';
import moment from 'moment-timezone';
import Vue from 'vue';
import { eventResponseStatus } from '../../../shared/enums/eventResponseStatus';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import focus from '../../../shared/directives/focus';
import srOnly from '../../../shared/directives/sr-only';
import CalendarEventInvitees from '../../../shared/PageFragments/CalendarEvent/CalendarEventInvitees.vue';
import CalendarOrganizerMeetingSlot from '../../../shared/PageFragments/CalendarEvent/CalendarOrganizerMeetingSlot.vue';
import { calendarUtil } from '../../../shared/utils/calendarUtil';
import CalendarInviteeMeetingSlot from '../../../shared/PageFragments/CalendarEvent/CalendarInviteeMeetingSlot.vue';
import * as dateUtil from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import AulaAlert from '../../../shared/components/AulaAlert.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import isEmpty from 'lodash/isEmpty';
import HeadlineAlert from '../../../shared/components/HeadlineAlert.vue';
import { format } from '../../../shared/utils/dateUtil';

export default {
  name: 'ResponseMeetingForm',
  directives: {
    focus,
    srOnly,
  },
  inject: {
    getCanHandleOthersEvents: { from: providerKeyTypes.canHandleOthersEvents, default: () => () => false },
  },
  props: {
    parent: { type: String, default: 'profile' },
    relatedNotification: { type: Object, default: null },
    requiredResponse: { type: Boolean, default: false },
  },
  data: function () {
    return {
      isLoading: false,
      showMissingTimeAlert: false,
      parentTypes: parentTypes,
      portalRoles: portalRoles,
      eventTypes: eventTypeEnum,
      eventResponseStatus: eventResponseStatus,
      moment: moment,
      selectedTimeSlotIndex: null,
      selectedInstitutionProfileId: null,
      timeSlot: {},
      participantSelected: {},
      concerningChild: '',
      concerningInstProfileId: null,
      blockingTimeSlot: false,
      isNotificationAnswered: false,
      timeSlotToDelete: {},
      timeSlotIndexToDelete: null,
      isShown: false,
      clearTimeSlotQueue: [],
      clearTimeslotErrorIntervalKey: null,
      isOpenMediaDetail: false,
      selectedMediaId: null,
    };
  },
  computed: {
    ...mapGetters({
      meetingEvent: types.GET_CURRENT_EVENT,
      activeGroup: types.GET_ACTIVE_GROUP,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      children: types.GET_CHILDREN,
      groupHomes: types.GET_GROUP_HOMES,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
      hasPermission: types.HAS_PERMISSION,
      isMobile: types.GET_IS_MOBILE,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
    }),
    canSendReminder() {
      return this.isCreator || this.isCoOrganizer;
    },
    lastReminderDateTime() {
      if (!this.meetingEvent?.lastReminderDateTime || (!this.isCreator && !this.isCoOrganizer)) {
        return null;
      }
      return format(this.meetingEvent.lastReminderDateTime, dateFormatEnum.LONG_DATE_TIME_WITH_DATE_PREFIX);
    },
    hasEnoughSlotsForEveryone() {
      const timeslots = this.meetingEvent.timeSlot?.timeSlots || [];
      let numberOfSlots = 0;
      for (const timeslot of timeslots) {
        const blockedAnswers = timeslot.answers.filter(answer => answer.instProfile === null);
        numberOfSlots += timeslot.timeSlotIndexes.length * this.numberOfParticipantsPerTimeslot - blockedAnswers.length;
      }
      return this.meetingInvitees.length <= numberOfSlots;
    },
    hasAnswered() {
      const concerningInvitee = this.meetingEvent.invitees.find(
        invitee => invitee.instProfile.id === this.concerningInstProfileId
      );
      return concerningInvitee?.responseType === eventResponseStatus.ACCEPTED;
    },
    numberOfParticipantsPerTimeslot() {
      return this.meetingEvent?.timeSlot.numberOfParticipantsPerTimeSlot || 1;
    },
    isParentalMeeting() {
      return calendarUtil.getIsParentalMeeting(this.meetingEvent?.type);
    },
    canRespondAsInvitee() {
      const canHandleMeeting = this.canHandleMeeting;
      const isAnsweringForChild = this.isGuardianOrOtpSelectingForChild;
      const isInvitee = this.isInvitee;

      return (!canHandleMeeting || isAnsweringForChild) && isInvitee;
    },
    eventInvitees() {
      return this.getInviteeNames();
    },
    okTextKey() {
      if (this.isChild) {
        return 'BUTTON_CLOSE';
      }
      if (this.canHandleMeeting) {
        if (this.isMobile) {
          return 'BUTTON_EDIT';
        }
        return 'BUTTON_CLOSE';
      }
      return 'BUTTON_SAVE';
    },
    editTextKey() {
      return this.canHandleMeeting ? 'BUTTON_EDIT' : 'CALENDAR_TIMESLOT_CANCEL_EVENT';
    },
    cancelTextKey() {
      if (this.canHandleMeeting) {
        return 'BUTTON_DELETE';
      }
      return 'CANCEL';
    },
    isHandlingOthersEvent() {
      return !this.isCreator && !this.isCoOrganizer && this.canHandleOthersEvents;
    },
    canHandleOthersEvents() {
      return this.getCanHandleOthersEvents();
    },
    isShownEditButton() {
      return this.canHandleMeeting || this.canDeleteResponse;
    },
    isShownOkButton() {
      return this.isInvitee || this.canHandleMeeting;
    },
    isChild() {
      return this.profile.role === portalRoles.CHILD;
    },
    isCoOrganizer() {
      if (this.meetingEvent == null || !Array.isArray(this.meetingEvent.coOrganizers)) {
        return false;
      }
      const institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
      if (this.delegatedContext?.institutionProfileId) {
        institutionProfileIds.push(this.delegatedContext.institutionProfileId);
      }
      return this.meetingEvent.coOrganizers.some(coOrganizer =>
        institutionProfileIds.includes(coOrganizer.instProfile.id)
      );
    },
    canHandleMeeting() {
      if (this.requiredResponse) {
        return false;
      }
      return this.isCreator || this.isCoOrganizer || this.isHandlingOthersEvent || this.isAddedOnlyInSchoolCalendar;
    },
    organizerNames() {
      const organizers = this.meetingEvent.coOrganizers || [];
      return organizers
        .map(organizer => this.$options.filters.displayProfileNameWithMetadata(organizer.instProfile))
        .join(', ');
    },
    getEventAttachments() {
      if (this.meetingEvent.attachments != null && this.meetingEvent.attachments.length > 0) {
        return this.meetingEvent.attachments.filter(att => att.media != null).map(att => att.media);
      } else {
        return [];
      }
    },
    isAddedOnlyInSchoolCalendar() {
      return (
        this.meetingEvent.hideInOwnCalendar &&
        this.hasPermission(permissionEnum.CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR)
      );
    },
    isDataValid() {
      return this.meetingEvent && this.meetingEvent !== undefined && this.meetingEvent.id !== undefined;
    },
    isInvitee() {
      if (
        this.meetingEvent.invitees != null &&
        this.meetingEvent.invitees.filter(
          inv =>
            inv.instProfile != null &&
            this.institutions.find(inst => inv.instProfile.id == inst.institutionProfileId) != null
        ).length > 0 &&
        this.institutions.find(inst => inst.institutionProfileId == this.calendarActiveInstProfileId) != null
      ) {
        return true;
      }

      if (
        this.meetingEvent.invitees != null &&
        this.meetingEvent.invitees.filter(
          inv => inv.instProfile != null && inv.instProfile.id == this.calendarActiveInstProfileId
        ).length > 0 &&
        this.institutions.find(inst => inst.institutionProfileId == this.calendarActiveInstProfileId) == null
      ) {
        return true;
      }

      if (this.isInvitedThroughGroupHomeRelation()) {
        return true;
      }

      return false;
    },
    isCreator() {
      const meetingEventCreatorId = this.meetingEvent.creator?.id;
      const activeInstitutionProfileId = this.calendarActiveInstProfileId;
      const creatorIsAPartOfInstitutionProfile = this.institutions.some(
        institution => institution.institutionProfileId == meetingEventCreatorId
      );
      const isActiveInstitutionProfile = this.institutions.some(
        institution => institution.institutionProfileId == activeInstitutionProfileId
      );

      if (creatorIsAPartOfInstitutionProfile && isActiveInstitutionProfile) {
        return true;
      }

      if (activeInstitutionProfileId != null) {
        return meetingEventCreatorId == activeInstitutionProfileId;
      }

      return false;
    },
    canDeleteResponse() {
      if (this.meetingEvent != null && this.meetingEvent.timeSlot != null) {
        for (const timeSlot of this.meetingEvent.timeSlot.timeSlots) {
          for (const answer of timeSlot.answers) {
            if (
              this.relatedNotification != null &&
              answer.concerningProfile &&
              (answer.concerningProfile.id == this.relatedNotification.relatedChildInstitutionProfileId ||
                answer.concerningProfile.id == this.relatedNotification.institutionProfileId)
            ) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.timeSlotToDelete = timeSlot;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.timeSlotIndexToDelete = answer.selectedTimeSlotIndex;
              return true;
            }
          }
        }
      }
      return false;
    },
    meetingInvitees() {
      const eventInvitees = this.meetingEvent?.invitees || [];
      const invitedRole = this.isParentalMeeting ? portalRoles.CHILD : portalRoles.EMPLOYEE;
      const invitees = eventInvitees.filter(invitee => invitee.instProfile?.role === invitedRole);

      invitees.sort(function (a, b) {
        const nameA =
          a.instProfile != null ? a.instProfile.firstName.toLowerCase() + a.instProfile.lastName.toLowerCase() : '';
        const nameB =
          b.instProfile != null ? b.instProfile.firstName.toLowerCase() + b.instProfile.lastName.toLowerCase() : '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return invitees;
    },
    isGuardianOrOtpSelectingForChild() {
      return (
        (this.meetingEvent.type == eventTypeEnum.PARENTAL_MEETING ||
          this.meetingEvent.type == eventTypeEnum.SCHOOL_HOME_MEETING) &&
        [portalRoles.GUARDIAN, portalRoles.OTP].includes(this.profile.role)
      );
    },
  },
  methods: {
    ...mapActions({
      loadEventById: types.GET_EVENT_BY_ID,
      respondToTimeSlotEvent: types.RESPOND_TO_TIMESLOT_EVENT,
      updateResponse: types.UPDATE_RESPONSE_TO_TIMESLOT_EVENT,
      deleteEvent: types.DELETE_EVENT,
      blockTimeSlot: types.BLOCK_TIMESLOT,
      removeBlockingOrResponseToTimeSlot: types.REMOVE_BLOCKING_OR_RESPONSE,
    }),
    closeMediaDetail() {
      this.isOpenMediaDetail = false;
    },
    getInviteeNames() {
      let inviteeNames = [];
      if (this.meetingEvent.invitees != null) {
        if (this.canHandleMeeting) {
          for (const invitee of this.meetingEvent.invitees) {
            if (invitee.instProfile && invitee.instProfile.role !== portalRoles.CHILD) {
              inviteeNames.push({
                name: Vue.filter('displayProfileNameWithMetadata')(invitee.instProfile),
                response: invitee.responseType,
              });
            }
          }
        }
        if (this.relatedNotification != null) {
          inviteeNames = this.getInviteesFromNotification(inviteeNames);
        }
        if (this.$route.query) {
          inviteeNames = this.getInviteesFromRoute(inviteeNames);
        }
        if (inviteeNames.length == 0) {
          for (const invitee of this.meetingEvent.invitees) {
            if (
              this.institutions.find(
                institution => invitee.instProfile != null && institution.institutionProfileId == invitee.instProfile.id
              ) != null
            ) {
              inviteeNames.push({
                name: Vue.filter('displayProfileNameWithMetadata')(invitee.instProfile),
                response: invitee.responseType,
              });
            }
          }
        }
        this.getGroupHomeInviteeNames().forEach(groupHomeInvitee => {
          inviteeNames.push(groupHomeInvitee);
        });
      }
      return inviteeNames.filter(
        (inviteeName, index, filteredInviteeNames) =>
          filteredInviteeNames.findIndex(filteredInviteeName => filteredInviteeName.name === inviteeName.name) === index
      );
    },
    getGroupHomeInviteeNames() {
      const groupHomeInvitees = [];

      if (this.meetingEvent.invitedGroupHomeChildren != null && this.meetingEvent.invitedGroupHomeChildren.length > 0) {
        this.meetingEvent.invitedGroupHomeChildren.forEach(invitedGroupHomeRelation => {
          const groupHomeChildId = invitedGroupHomeRelation.regardingChildId;
          const isGroupHomeRelatedToChild =
            this.relatedNotification?.relatedChildInstitutionProfileId === groupHomeChildId;
          if (isGroupHomeRelatedToChild || this.profile.role === portalRoles.EMPLOYEE) {
            groupHomeInvitees.push({
              name: Vue.filter('groupHomeEventInviteeWithMetaData')(invitedGroupHomeRelation),
              response: invitedGroupHomeRelation.responseType,
            });
          }
        });
      }
      return groupHomeInvitees;
    },
    isInvitedThroughGroupHomeRelation() {
      if (this.meetingEvent && this.meetingEvent.invitedGroupHomeChildren) {
        const groupHomeInviteeIds = this.meetingEvent.invitedGroupHomeChildren;
        const childIds = this.children.map(child => child.id);
        const groupHomeIds = this.groupHomes.map(groupHome => groupHome.id);

        const isRelatedToUsersGroupHome = groupHomeInviteeIds.some(groupHomeInvitee =>
          groupHomeIds.includes(groupHomeInvitee.groupHomeId)
        );
        const isRelatedToChildrenInCare = groupHomeInviteeIds.some(groupHomeInvitee =>
          childIds.includes(groupHomeInvitee.regardingChildId)
        );

        if (isRelatedToUsersGroupHome === true && isRelatedToChildrenInCare === true) {
          return true;
        }
      }
      return false;
    },
    getInviteesFromNotification(inviteeNames) {
      const relatedInstProfileId = this.relatedNotification.institutionProfileId;
      const relatedInvitee = this.meetingEvent.invitees.find(inv => inv.instProfile.id == relatedInstProfileId);
      const relatedChildInstProfileId = this.relatedNotification.relatedChildInstitutionProfileId;
      const relatedChild = this.meetingEvent.invitees.find(inv => inv.instProfile.id == relatedChildInstProfileId);
      if (relatedChild != null) {
        this.concerningChild = Vue.filter('displayProfileNameWithMetadata')(relatedChild.instProfile);
        this.concerningInstProfileId = relatedChild.instProfile.id;
      }
      if (relatedInvitee?.instProfile != null) {
        const concerningName = Vue.filter('displayProfileNameWithMetadata')(relatedInvitee.instProfile);
        inviteeNames.push({
          name: concerningName,
          response: relatedInvitee.responseType,
        });
      }
      return inviteeNames;
    },
    getInviteesFromRoute(inviteeNames) {
      if (this.$route.query.otherContactPersons) {
        const otherContactPersons = this.$route.query.otherContactPersons.split(',');
        for (const invitee of otherContactPersons) {
          inviteeNames.push({
            name: invitee,
            response: eventResponseStatus.WAITING,
          });
        }
      }

      if (this.$route.query.child) {
        const relatedChild = this.meetingEvent.invitees.find(inv => inv.instProfile.id == this.$route.query.child);
        if (relatedChild) {
          this.concerningChild = Vue.filter('displayProfileNameWithMetadata')(relatedChild.instProfile);
          this.concerningInstProfileId = relatedChild.instProfile.id;
        }
      }
      return inviteeNames;
    },
    attachmentClicked(mediaId) {
      this.isOpenMediaDetail = true;
      this.selectedMediaId = mediaId;

      if (this.relatedNotification) {
        return;
      }
      if (this.$route.path.includes('/gruppe')) {
        this.$router.push({
          name: 'group-calendar-view-media-meeting-event',
          params: {
            groupId: this.$route.params.groupId,
            eventId: this.meetingEvent.id,
            mediaId: mediaId,
          },
        });
      } else {
        this.$router.push({
          name: 'viewMediaMeetingEvent',
          params: { eventId: this.meetingEvent.id, mediaId: mediaId },
        });
      }
    },
    initConcerningProfileId() {
      if (this.isInvitee) {
        this.concerningInstProfileId = parseInt(this.calendarActiveInstProfileId);
      }
    },
    show() {
      this.showMissingTimeAlert = false;
      this.selectedTimeSlotIndex = null;
      this.$refs.modalResponseMeeting.show();
      this.isShown = true;
      this.isOpenMediaDetail = false;
      this.initConcerningProfileId();
    },
    hide() {
      this.$refs.modalResponseMeeting.hide();
      this.isShown = false;
    },
    getTimeslotSections(timeslot) {
      const timeslotSections = timeslot.timeSlotIndexes || [];
      return timeslotSections.map((section, index) => {
        const answers = timeslot.answers.filter(answer => answer.selectedTimeSlotIndex === index);
        return {
          ...section,
          answers,
        };
      });
    },
    cancelDeleteMeetingEvent() {
      this.$refs.deleteModal.hide();
      this.$refs.modalResponseMeeting.show();
    },
    deleteMeetingEvent() {
      this.isLoading = true;
      this.deleteEvent({ eventForm: this.meetingEvent }).then(() => {
        this.$refs.deleteModal.hide();
        this.$emit('removeEventFromCalendar', [this.meetingEvent.id]);
        this.isLoading = false;
        this.$refs.modalResponseMeeting.hide();
        this.$refs.modalResponseMeeting.hide();
        this.$refs.modalResponseMeeting.hide();
      });
    },
    editMeetingEvent() {
      if (this.profile.communicationBlock) {
        this.$refs.blockedCommunicationModal.show();
      } else {
        this.hideDialog();
        if (this.parent == parentTypes.GROUP) {
          this.$router.push({
            name: 'group-calendar-edit-meeting-event',
            params: {
              eventId: this.meetingEvent.id,
              groupId: this.activeGroup.id,
            },
            query: { parent: this.parent },
          });
        } else {
          this.$router.push({
            name: 'editMeetingEvent',
            params: { eventId: this.meetingEvent.id },
            query: { parent: this.parent },
          });
        }
      }
    },
    selectedOnBehalfConfirmModal(timeSlotIndex, participant, timeSlot) {
      if (participant == null) {
        this.blockingTimeSlot = true;
        this.participantSelected = this.calendarActiveInstProfileId;
      } else {
        this.participantSelected = participant.instProfile.id;
      }
      this.timeSlot = timeSlot;
      this.selectedTimeSlotIndex = timeSlotIndex;
      Vue.nextTick(this.$refs.selectOnBehalfModal.show);
    },
    cancelClickedSelectOnBehalfModal() {
      this.blockingTimeSlot = false;
      this.$refs.selectOnBehalfModal.hide();
    },
    cancelClearTimeSlotModal() {
      this.$refs.clearTimeSlotModal.hide();
      this.showMeetingModal();
    },
    cancelUpdateTimeSlotModal() {
      this.$refs.updateTimeSlotModal.hide();
      this.showMeetingModal();
    },
    showMeetingModal() {
      Vue.nextTick(() => {
        this.$refs.modalResponseMeeting.show();
        this.selectedTimeSlotIndex = null;
      });
    },
    showClearTimeSlotModal(timeSlotIndex, timeSlot, institutionProfileId = null) {
      this.timeSlot = timeSlot;
      this.selectedTimeSlotIndex = timeSlotIndex;
      this.selectedInstitutionProfileId = institutionProfileId || null;
      if (this.canHandleMeeting) {
        this.clearTimeSlot();
      } else {
        this.$refs.modalResponseMeeting.hide();
        Vue.nextTick(() => {
          this.$refs.clearTimeSlotModal.show();
        });
      }
    },
    clearTimeSlot() {
      this.isLoading = true;
      const promise = this.removeBlockingOrResponseToTimeSlot({
        eventId: this.meetingEvent.id,
        timeSlotId: this.timeSlot.id,
        timeSlotIndex: this.selectedTimeSlotIndex,
        concerningInstitutionProfileId: this.selectedInstitutionProfileId,
      })
        .then(() => {
          this.$refs.clearTimeSlotModal.hide();

          if (this.clearTimeSlotQueue.length === 1 && !this.canHandleMeeting) {
            this.$emit('afterResponseEvent');
            this.hideDialog();
          }
        })
        .catch(() => {
          if (this.isCreator && this.clearTimeslotErrorIntervalKey === null) {
            this.clearTimeslotErrorIntervalKey = setInterval(() => {
              if (this.clearTimeSlotQueue.length <= 1) {
                clearInterval(this.clearTimeslotErrorIntervalKey);
                this.clearTimeslotErrorIntervalKey = null;
              }
            }, 300);
          }
        })
        .finally(() => {
          const index = this.clearTimeSlotQueue.indexOf(promise);
          this.clearTimeSlotQueue.splice(index, 1);
          this.isLoading = false;
          this.reloadAfterSelectedOnBehalf();
        });
      this.clearTimeSlotQueue.push(promise);
    },
    updateTimeSlotResponse() {
      this.isLoading = true;
      this.updateResponse({
        eventId: this.meetingEvent.id,
        responseType: 'accepted',
        concerningInstProfileId: this.concerningInstProfileId,
        timeSlotId: this.timeSlot.id,
        timeSlotIndex: this.selectedTimeSlotIndex,
      }).then(rsp => {
        if (rsp && (rsp.code === 1000 || rsp.code === 409) && rsp.message != null && rsp.message.includes('booked')) {
          this.$refs.timeSlotAlreadyBooked.show();
          this.isLoading = false;
        } else {
          this.$emit('afterResponseEvent');
          this.hideDialog();
        }
      });
    },
    selectedOnBehalf() {
      this.isLoading = true;
      if (this.participantSelected != null) {
        this.concerningInstProfileId = parseInt(this.participantSelected);
      }
      if (this.blockingTimeSlot) {
        this.blockTimeSlot({
          eventId: this.meetingEvent.id,
          timeSlotId: this.timeSlot.id,
          timeSlotIndex: this.selectedTimeSlotIndex,
        }).then(rsp => {
          if (rsp && (rsp.code === 1000 || rsp.code === 409) && rsp.message != null && rsp.message.includes('booked')) {
            this.$refs.selectOnBehalfModal.hide();
            this.$refs.timeSlotAlreadyBooked.show();
            this.isLoading = false;
          } else {
            this.reloadAfterSelectedOnBehalf();
          }
        });
      } else {
        if (this.hasAnswered) {
          this.updateResponse({
            eventId: this.meetingEvent.id,
            responseType: 'accepted',
            concerningInstProfileId: this.concerningInstProfileId,
            timeSlotId: this.timeSlot.id,
            timeSlotIndex: this.selectedTimeSlotIndex,
          }).then(rsp => {
            if (
              rsp &&
              (rsp.code === 1000 || rsp.code === 409) &&
              rsp.message != null &&
              rsp.message.includes('booked')
            ) {
              this.$refs.selectOnBehalfModal.hide();
              this.$refs.timeSlotAlreadyBooked.show();
              this.isLoading = false;
            } else {
              this.reloadAfterSelectedOnBehalf();
            }
          });
        } else {
          this.respondToTimeSlotEvent({
            eventId: this.meetingEvent.id,
            responseType: 'accepted',
            concerningInstProfileId: this.concerningInstProfileId,
            timeSlotId: this.timeSlot.id,
            timeSlotIndex: this.selectedTimeSlotIndex,
          }).then(rsp => {
            if (
              rsp &&
              (rsp.code === 1000 || rsp.code === 409) &&
              rsp.message != null &&
              rsp.message.includes('booked')
            ) {
              this.$refs.selectOnBehalfModal.hide();
              this.$refs.timeSlotAlreadyBooked.show();
              this.isLoading = false;
            } else {
              this.reloadAfterSelectedOnBehalf();
            }
          });
        }
      }
    },
    reloadAfterSelectedOnBehalf() {
      this.loadEventById({ eventId: this.meetingEvent.id });
      this.$refs.selectOnBehalfModal.hide();
      this.isLoading = false;
      this.blockingTimeSlot = false;
    },
    timeSlotSelectedChange(timeSlot, timeSlotIndex) {
      this.timeSlot = timeSlot;
      this.selectedTimeSlotIndex = timeSlotIndex;
    },
    getIsSelectedSlot(timeSlot, timeSlotIndex) {
      return timeSlot === this.timeSlot && timeSlotIndex === this.selectedTimeSlotIndex;
    },
    hideDialog() {
      this.selectedTimeSlotIndex = null;
      this.participantSelected = {};
      this.timeSlot = {};
      this.$refs.updateTimeSlotModal.hide();
      this.$refs.modalResponseMeeting.hide();
      this.isLoading = false;
      if (this.$route.path.includes('samtale')) {
        if (this.$route.path.includes('/gruppe')) {
          this.$router.push({
            name: 'group-calendar',
            params: { groupId: this.$route.params.groupId },
          });
        } else {
          this.$router.push({ path: '/kalender' });
        }
      }
    },
    handleCancelButton() {
      this.isLoading = false;
      if (this.canHandleMeeting) {
        this.$refs.modalResponseMeeting.hide();
        this.onPressDelete();
      } else {
        this.$refs.modalResponseMeeting.hide();
      }
      this.closeMeetingForm();
    },
    onPressDelete() {
      this.$refs.deleteModal.show();
    },
    closeMeetingForm() {
      this.$refs.modalResponseMeeting.hide();
      if (!isEmpty(this.relatedNotification)) {
        return;
      }
      if (this.$route.name === 'group-calendar-view-meeting-event') {
        this.$router.push({
          name: 'group-calendar',
          params: { groupId: this.$route.params.groupId },
        });
      } else {
        this.$router.push({ name: 'calendar' });
      }
    },
    onOkButtonClicked() {
      if (this.isChild) {
        this.hide();
        return;
      }
      if (this.isMobile && this.canHandleMeeting) {
        this.editMeetingEvent();
        return;
      }
      this.handleTimeSlotRespond();
    },
    handleTimeSlotRespond() {
      if (this.canHandleMeeting) {
        this.$refs.modalResponseMeeting.hide();
        if (this.$route.path.includes('samtale')) {
          if (this.$route.path.includes('/gruppe')) {
            this.$router.push({
              name: 'group-calendar',
              params: { groupId: this.$route.params.groupId },
            });
          } else {
            this.$router.push({ name: 'calendar' });
          }
        }
      } else {
        if (!this.meetingEvent.isDeadlineExceeded) {
          if (this.selectedTimeSlotIndex != null) {
            if (this.relatedNotification != null && this.relatedNotification.relatedChildInstitutionProfileId != null) {
              this.concerningInstProfileId = this.relatedNotification.relatedChildInstitutionProfileId;
            } else if (this.relatedNotification != null && this.relatedNotification.institutionProfileId != null) {
              this.concerningInstProfileId = this.relatedNotification.institutionProfileId;
            }
            if (!this.concerningInstProfileId && this.meetingEvent.invitees) {
              this.concerningInstProfileId = this.meetingEvent.invitees.find(
                inv => this.institutions.find(inst => inst.institutionProfileId === inv.instProfile.id) != null
              ).instProfile.id;
            }
            if (this.hasAnswered) {
              this.$refs.modalResponseMeeting.hide();
              Vue.nextTick(this.$refs.updateTimeSlotModal.show());
              if (this.$route.path.includes('samtale')) {
                if (this.$route.path.includes('/gruppe')) {
                  this.$router.push({
                    name: 'group-calendar',
                    params: { groupId: this.$route.params.groupId },
                  });
                } else {
                  this.$router.push({ path: '/kalender' });
                }
              }
            } else {
              this.isLoading = true;
              this.respondToTimeSlotEvent({
                eventId: this.meetingEvent.id,
                responseType: 'accepted',
                concerningInstProfileId: this.concerningInstProfileId,
                timeSlotId: this.timeSlot.id,
                timeSlotIndex: this.selectedTimeSlotIndex,
              }).then(rsp => {
                if (rsp && (rsp.code === 1000 || rsp.code === 409)) {
                  this.$refs.timeSlotAlreadyBooked.show();
                  this.isLoading = false;
                } else {
                  this.$emit('afterResponseEvent', this.relatedNotification.notificationId);
                  this.hideDialog();
                }
              });
            }
          } else {
            this.showMissingTimeAlert = true;
          }
        }
      }
    },
    getTimeslotFullDateTime(timeslot) {
      return dateUtil.format(timeslot.startDate, dateFormatEnum.FULL_DATE);
    },
  },
  components: {
    HeadlineAlert,
    AulaButton,
    AulaAlert,
    CalendarInviteeMeetingSlot,
    CalendarOrganizerMeetingSlot,
    CalendarEventInvitees,
    AttachmentList,
    AttachmentThumbnails,
    MediaDetails,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.response-timeslot {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  .timeslot {
    min-width: 450px;
    margin: 0 1px;
    @include breakpoint-lg-down() {
      min-width: unset;
      width: 100%;
      padding-bottom: 50px;
    }
    .header {
      width: 100%;
      text-align: center;
      font-weight: bold;
      padding: 10px 40px;
      background-color: $color-grey-dark;

      &::first-letter {
        text-transform: capitalize;
      }
    }
    .slot-picker-time-break {
      color: $color-help-text;
    }
    .slot-picker {
      &.accepted {
        cursor: default;
        color: $color-grey;
        font-weight: normal;
        .checkbox {
          visibility: hidden;
        }
      }
      display: flex;
      align-content: center;
      padding: 5px 0;
      margin-left: 20px;
      .radio {
        cursor: pointer;
        width: 25px;
        .icon-Aula_search {
          line-height: 30px;
        }
      }
      .time,
      .time {
        margin: 0 5px;
        font-weight: bold;
      }
    }
  }
  /deep/ .slot-picker {
    .dropdown-menu {
      &.show {
        max-width: calc(100vw - 220px);
        height: 500px;
        overflow: hidden;
        overflow-y: scroll;
        height: fit-content;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;

        @include breakpoint-lg() {
          max-width: 300px;
        }

        a {
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .theme-employee & {
            color: $color-primary-darker-employee;
          }
          .theme-guardian & {
            color: $color-primary-darker-guardian;
          }
          .theme-child & {
            color: $color-primary-darker-child;
          }
          .theme-admin & {
            color: $color-primary-darker-admin;
          }
        }
      }
    }
  }
}

.response-header {
  --questionmark-icon-background-color: var(--color-grey-dark);

  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-grey-dark;
  margin-bottom: 10px;
  .response-title {
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .response-persons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .participant {
      flex-grow: 1;
      .title {
        font-weight: bold;
      }
      .organizer {
        flex-grow: 1;
        border-bottom: 1px solid var(--color-grey-dark);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.response-description {
  margin-bottom: 10px;
}

.aula-close {
  cursor: pointer;
  float: right;
}

.theme-employee {
  .slot-picker.selected {
    color: $color-primary-base-employee;
  }
}

.theme-child {
  .slot-picker.selected {
    color: $color-primary-base-child;
  }
}

.theme-admin {
  .slot-picker.selected {
    color: $color-primary-base-admin;
  }
}

.theme-guardian {
  .slot-picker.selected {
    color: $color-primary-base-guardian;
  }
}

.mobile-footer {
  background: $color-grey-base;
  padding: 10px;
  width: 100%;
  transform: translateX(-20px);
  position: fixed;
  z-index: $aula-modal-button-z-index;
  bottom: 0;
  text-align: center;

  button {
    text-transform: uppercase;
    font-size: 12px;

    .theme-employee & {
      color: $color-primary-dark-employee;
    }

    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }

    .theme-child & {
      color: $color-primary-dark-child;
    }
  }
}
</style>

import { EventRenderer } from './eventRenderer';
import { CalendarEventModel } from '../../../shared/models/calendarEvent.model';
import { ResourceModel } from '../../../shared/models/resource.model';
import { NotificationModel } from '../../../shared/models/notification.model';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import moment from 'moment-timezone';
import Vue from 'vue';
import { htmlUtil } from '../../../shared/utils/htmlUtil';

export class NoticeBoardEventRenderer extends EventRenderer {
  private readonly isStart: boolean;

  constructor(
    { event, el }: { event: CalendarEventModel; el: HTMLElement; isStart: boolean },
    resources: ResourceModel[],
    notifications: NotificationModel[],
    profile: InstitutionProfileModel,
    delegatedContext: InstitutionProfileModel,
    noOfResources: number,
    isStart: boolean
  ) {
    super(
      {
        event,
        el,
      },
      resources,
      notifications,
      profile,
      delegatedContext,
      noOfResources
    );

    this.isStart = isStart;
  }

  override render(): string {
    if (this.shouldBeInvisible) {
      this.element.style.display = 'none';
      return '';
    }

    const dateHtml = htmlUtil.createSimpleDiv(
      null,
      'day-view-date-event',
      `${htmlUtil.boldedText(this.eventStartDate)} ${
        this.eventEndMonth != this.eventStartMonth ? this.eventStartMonth.toUpperCase() : ''
      }
      ${this.eventEndDate ? htmlUtil.boldedText(' - ' + this.eventEndDate) : ''} ${
        this.eventEnd != null ? this.eventEndMonth.toUpperCase() : ''
      }`
    );

    const timeHtml = htmlUtil.createSimpleDiv(null, 'day-view-time-event', this.eventTimeText);
    const titleHtml = htmlUtil.createSimpleDiv(null, 'day-view-title-event', this.eventTitle);

    const htmlChildren = [dateHtml, timeHtml, titleHtml];

    return htmlUtil.createDiv(null, 'day-view-event-group', htmlChildren);
  }

  private get eventStartDate() {
    return moment(this.eventStart).format('DD');
  }

  private get eventStartMonth() {
    return moment(this.eventStart).format('MMM');
  }

  private get eventEndMonth() {
    return moment(this.eventEnd).format('MMM');
  }

  private get eventEndDate() {
    if (this.endsOnDifferentDate && !this.allDay && !this.extendedProps.repeating) {
      return moment(this.eventEnd).format('DD');
    }
    return null;
  }

  private get endTime() {
    return this.eventEnd != null ? moment(this.eventEnd).format('HH:mm') : '';
  }

  private get eventTimeText() {
    return this.allDay
      ? Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_ALL_DAY')
      : 'Kl. ' + moment(this.eventStart).format('HH:mm') + ' - ' + this.endTime;
  }

  private get shouldBeInvisible() {
    return this.endsOnDifferentDate && !this.allDay && !this.extendedProps.repeating && !this.isStart;
  }

  private get endsOnDifferentDate() {
    return (
      moment(this.extendedProps.endDateTime).diff(moment(this.extendedProps.startDateTime), 'days') >= 1 ||
      moment(this.extendedProps.endDateTime).date() !== moment(this.extendedProps.startDateTime).date()
    );
  }
}

<template>
  <div class="widget">
    <h2 class="widget-title">Elevplaner med nyt</h2>
    <div v-if="loading" class="widget-loader">
      <i class="fa fa-spinner"></i>
      Loading...
    </div>
    <div v-if="hasErrors">
      <ul class="errors">
        <li v-for="error in errors" v-bind:key="error" class="errors__error">{{ error }}</li>
      </ul>
    </div>
    <div v-if="!loading">
      <ul v-if="items && items.length" class="widget">
        <li v-for="item in items" v-bind:key="item.id" class="widget__item">
          <div v-if="item.plans && item.plans.length > 0">
            <ul class="widgetlist">
              <li
                v-for="plan in item.plans.slice(0, maxShown)"
                v-bind:key="plan.id"
                class="widgetlist__item"
                v-on:click="submitLink(widgetIdentifier + 'plan' + plan.id)"
                v-on:keypress="(e) => activateItem(e, 'plan' + plan.id)"
                :style="getAvatarIcon()"
                tabindex="0"
                role="button"
                :title="`Se ${plan.categoriesName} (${plan.studentName}) i Meebook`"
              >
                <form
                  v-bind:action="getApiBaseUrl() + 'teacherstudentplanlinkauth'"
                  method="post"
                  target="_blank"
                  rel="noopener"
                  v-bind:ref="widgetIdentifier + 'plan' + plan.id"
                >
                  <input type="hidden" name="studentPlanId" v-bind:value="plan.id" />
                  <input type="hidden" name="sessionUUID" v-bind:value="sessionUUID" />
                  <input type="hidden" :id="`aulaJWT${widgetIdentifier + 'plan' + plan.id}`" name="aulaJWT" value="" />
                  <input type="hidden" name="userProfile" v-bind:value="userProfile" />
                  <input type="hidden" name="currentWeekNumber" v-bind:value="getThisYearWeek()" />
                </form>
                <div class="widgetlist__item__title">
                  <span>{{ plan.categoriesName }}</span>
                </div>
                <div class="widgetlist__item__subtitle">
                  <span>{{ plan.studentName }}</span>
                </div>
                <div class="widgetlist__item__dot"></div>
              </li>
            </ul>
            <button
              v-if="item.plans.length > 3"
              class="widgetlist__showall"
              v-on:click="toggleShowAll"
            >
              {{showAll ? 'Se færre' : 'Se alle'}}
            </button>
          </div>
          <div v-else>
            <p>Ingen elevplaner med nyt</p>
          </div>
        </li>
      </ul>
      <div v-else-if="!hasErrors">
        <p>Ingen elevplaner med nyt</p>
      </div>
    </div>
  </div>
</template>

<script>
  module.exports = {
    props: {
      axios: Function,
      moment: Function,
      getAulaToken: Function,
      devServer: String,
      sessionUUID: String,
      placement: String,
      currentWeekNumber: String,
      isMobileApp: Boolean,
      assuranceLevel: Number,
      userProfile: String,
      childFilter: [String, Array],
      institutionFilter: [String, Array],
      group: String,
      meebookImgBaseUrl: {
        type: String,
        default: 'https://app.meebook.com/images/aula/',
      },
      widgetIdentifier:  {
        type: String,
        default: 'teacherChangedStudentPlans-',
      },
      meebookApiBaseUrl: String,
      meebookApiTestData: String,
    },
    computed: {
      maxShown() {
        return this.showAll ? Infinity : 3;
      },
      hasErrors() {
        return this.errors && this.errors.length;
      },
    },
    methods: {
      getThisYearWeek() {
        this.moment.locale('da');
        return this.moment().format(this.moment.HTML5_FMT.WEEK);
      },
      getAvatarIcon() {
        return `background-image: url(${this.meebookImgBaseUrl}default-avatar-2x.png)`;
      },
      getClient() {
        return this.axios.create({
          baseURL: this.getApiBaseUrl(),
          timeout: 30000,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.aulaToken,
            'sessionUUID': this.sessionUUID,
            'X-Version': '1.0',
          }
        });
      },
      getApiBaseUrl() {
        // production url (defined here because there wil not be an API_BASE_URL on aula live)
        let url = 'https://app.meebook.com/aulaapi/';
        // if dev server is set, use that
        if (this.meebookApiBaseUrl !== undefined) {
          // could be localhost (or other local vhost) or development server
          url = this.meebookApiBaseUrl;
        }

        return url;
      },
      activateItem(e, index) {
        if (e.code === 'Enter' || e.code === 'Space') {
          this.submitLink(index);
        }
      },
      submitLink(index) {
        let aulaJwtInputElement = document.getElementById('aulaJWT' + index);
        // insert jwt token value into form
        aulaJwtInputElement.value = this.aulaToken;
        // send form
        this.$refs[index][0].submit();
        // reset aula jwt token input value to null
        aulaJwtInputElement.value = null;
      },
      getTestData() {
        // shold only be used when building html. Not "real" data
        this.loading = false;
        return JSON.parse(this.meebookApiTestData);
      },
      toggleShowAll() {
        this.showAll = !this.showAll;
      },
      loadContent() {
        // if we are just testing layout/styles we can use static test data
        if (this.meebookApiTestData !== undefined) {
          this.items = this.getTestData();
          return;
        }

        // call aula api for dynamic data
        let http = this.getClient();
        http.get(
          'teacherchangedstudentplan/all',
          {
            params: {
              currentWeekNumber: this.getThisYearWeek(),
              userProfile: this.userProfile,
              childFilter: this.childFilter,
              institutionFilter: this.institutionFilter,
            }
          }
        )
          .then((response) => {
            this.loading = false;
            this.items = response.data || [];
          })
          .catch(({ response: { data = {} } = {}}) => {
            this.loading = false;
            if (data.exceptionMessage) {
              this.errors.push(data.exceptionMessage);
            } else {
              this.errors.push('Elevplaner kunne ikke hentes.');
            }
          });
      }
    },
    data: () => ({
      loading: true,
      items: '',
      errors: [],
      showAll: false,
      aulaToken: null
    }),
    mounted() {
      this.aulaToken = this.getAulaToken();
    },
    watch: {
      aulaToken: function () {
        if (this.aulaToken !== undefined) {
          this.loadContent();
        }
      }
    }
  }
</script>

<style scoped>
  .widget-loader{font-family:'Lato', 'Avenir', Helvetica, Arial, sans-serif;font-size:16px;margin:20px 0}.widget-loader>.fa{animation-name:spin;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear;animation-fill-mode:both;margin-right:10px}@keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}.errors{margin-top:20px}.errors__error{display:block;padding:15px 20px;background-color:#fff;color:#b50050}*{box-sizing:border-box}ul{margin:0;padding:0;list-style-type:none}li{margin:0;width:100%}.widget-title{display:block;text-align:left;font-family:'Lato', 'Avenir', Helvetica, Arial, sans-serif;font-size:17px;font-weight:700;text-transform:uppercase;margin-bottom:0;margin-top:30px}.widget{text-align:left}.widget__item__name{display:block;font-size:14px;font-weight:700;text-transform:capitalize;color:#5D6C79;margin-top:30px;margin-bottom:10px}.widgetlist{text-align:left}.widgetlist__item{position:relative;padding:15px 20px;background-color:#fff;margin-bottom:5px;cursor:pointer;outline:0}.widgetlist__item:focus{background-color:rgba(255,255,255,0.4)}.widgetlist__item__title{font-size:16px;font-weight:700}.widgetlist__item__subtitle{font-size:11px;font-weight:700;color:#6A7681;text-transform:capitalize}.widgetlist__item__subtitle::first-letter{text-transform:uppercase}.widgetlist__showall{display:block;padding:0;margin-top:5px;font-size:14px;font-weight:700;text-decoration:underline;cursor:pointer;background-color:transparent;border:0;outline:none}.widgetlist__showall:focus{opacity:0.5}.widget{margin-top:20px}.widgetlist__item__dot{position:absolute;top:50%;left:0;transform:translate3d(-50%, -50%, 0);height:14px;width:14px;border-radius:50%;border:1px solid #fff;background-color:#ad0945}.widgetlist__item{padding:15px 20px 15px 70px;background-repeat:no-repeat;background-position:10px center;background-size:45px}
</style>

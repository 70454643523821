<template>
  <div>
    <div class="aula-documentsCommonList-container">
      <h1 tabindex="0" class="document-title">
        <a @click="deselectChild">{{ title }}</a>
        <span v-if="selectedChildName">/</span>
        <b>{{ selectedChildName }}</b>
      </h1>

      <div v-if="loadingData && !documentCollection" class="text-container">
        <i class="el-icon-loading"></i>
      </div>

      <div v-else>
        <div v-if="isNothingToShow" class="text-container">
          Der er ikke delt dokumenter med dig
        </div>
        <div v-else>
          <div class="table" v-if="!!selectedChildName">
            <div class="table-row header cursor-default">
              <div class="table-cell"></div>
              <div class="table-cell text-left">Titel</div>
              <div class="table-cell text-left">Delt af</div>
              <div class="table-cell fit-content text-left">Senest ændret</div>
            </div>

            <div
              v-for="document in documentsForSelectedChild"
              :key="document.id"
              tabindex="0"
              role="button"
              aria-label="Se fortroligt dokument"
              class="table-row body"
            >
              <div class="table-cell not-collapsed check" style="width: 50px">
                <i class="icon-Aula_paper" style="font-size: 1.5em"></i>
              </div>
              <div class="table-cell title not-collapsed">
                <b>
                  <a :href="decorateUrl(document.url)" target="_blank"  @click="onOpenDocument(document.id)">
                    {{ document.fileName }}
                  </a>
                </b>
                <span v-if="!document.isRead" class="new-document-mark"
                >Ny</span
                >
              </div>
              <div class="table-cell not-collapsed">
                {{ document.sharedByName }}
              </div>
              <div class="table-cell not-collapsed" style="width: 200px">
                <span>{{ toDateString(document.createdDate) }}</span>
                <span style="margin-left: 8px">{{ toTimeString(document.createdDate) }}</span>
              </div>

              <div class="table-cell collapsed-only">
                <div class="item" style="text-align: left;">
                  <b>
                    <a :href="decorateUrl(document.url)" target="_blank"  @click="onOpenDocument(document.id)">
                      {{ document.fileName }}
                    </a>
                    <span v-if="!document.isRead" class="new-document-mark"
                    >Ny</span
                    >
                  </b>
                </div>
                <div class="item pull-left">
                  <span>Delt af </span>
                  {{ document.sharedByName }}
                </div>
                <div class="item pull-left">
                  <span>Senest ændret</span>&nbsp;
                  <span>{{ toDateString(document.createdDate) }}</span>&nbsp;
                  <span>{{ toTimeString(document.createdDate) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="table">
            <div class="table-row header cursor-default">
              <div class="table-cell"></div>
              <div class="table-cell text-left"  style="width: calc(100% - 50px);">Barn</div>
            </div>
            <div
              v-for="child in children"
              :key="child"
              tabindex="1"
              role="button"
              aria-label="Vælg barn"
              class="table-row body"
            >
              <div class="table-cell not-collapsed check" style="width: 50px">
                <i class="fas fa-user" style="font-size: 1.5em"></i>
              </div>

              <div class="table-cell not-collapsed title" style="width: calc(100% - 50px);">
                <b>
                  <a @click="selectChild(child)">
                    {{ child }}
                  </a>
                </b>
              </div>
              <div class="table-cell collapsed-only title" style="width: calc(100% - 50px);">
                <b>
                  <a @click="selectChild(child)">
                    {{ child }}
                  </a>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    axios: Function,
    getAulaToken: Function,
    userProfile: String,
    assuranceLevel: Number,
    childFilter: Array,
    institutionFilter: Array,
    isMobileApp: Boolean
  },
  data() {
    return {
      uriToDocumentListApi:
        "https://aulawidgetapi.nordiccloud.dk/api/ConfidentialDocumentsShared",
      aulaToken: null,
      documentCollection: null,
      title: "",
      selectedChildName: null,
      isChildSelectorHidden: false,
      loadingData: true,
    };
  },

  computed: {
    children() {
      if (this.documentCollection) {
        const childrenNames = this.documentCollection.map((c) => c.childName);
        return ([...new Set(childrenNames)]).sort((a, b) => a.localeCompare(b));
      }
      return null;
    },

    documentsForSelectedChild() {
      if (this.selectedChildName) {
        return (this.documentCollection
          .filter((document) => document.childName == this.selectedChildName)
          .sort((a, b) => a.createdDate < b.createdDate));
      }
      return [];
    },

    isNothingToShow() {
      return (
        !this.loadingData &&
        this.documentCollection !== null &&
        (!Array.isArray(this.documentCollection) || !this.documentCollection.length)
      );
    },
  },

  mounted() {
    this.aulaToken = this.getAulaToken();
  },

  watch: {
    aulaToken() {
      if (this.aulaToken) {
        this.loadConfidentialDocumentsSharedFromApi();
      }
    },
  },

  methods: {
    async loadConfidentialDocumentsSharedFromApi() {
      this.loadingData = true;

      try {
        const documentsResult = await this.axios.get(
          `${this.uriToDocumentListApi}?userProfile=${this.userProfile}&assuranceLevel=${this.assuranceLevel}&childFilter=${this.childFilter}&institutionFilter=${this.institutionFilter}&isMobileApp=${this.isMobileApp}`,
          { headers: { Authorization: `Bearer ${this.aulaToken}` } }
        );
        documentsResult.data.documents.forEach(d => d.createdDate = new Date(d.created));
        this.documentCollection = documentsResult.data.documents;
        this.title = documentsResult.data.title;
      }
      catch {
        this.documentCollection = [];
      }
      finally {
        this.loadingData = false;
        this.updateUnread();
        this.setupWidget();
      }
    },

    setupWidget() {
      if (this.children && this.children.length == 1) {
        this.isChildSelectorHidden = true;
        this.selectChild(this.children[0]);
      }
    },

    selectChild(childName) {
      this.selectedChildName = childName;
    },

    deselectChild() {
      this.selectedChildName = null;
    },

    decorateUrl(url) {
      return `${url}&token=${this.aulaToken}`;
    },

    onOpenDocument() {
      setTimeout(() => {
        this.loadConfidentialDocumentsSharedFromApi();
      }, 4000);
    },

    updateUnread() {
      if (this.documentCollection) {
        const noOfUnread = this.documentCollection.filter(
          (dc) => dc.isRead === null
        ).length;
        this.$emit("updateUnread", noOfUnread);
      }
    },

    toDateString(dateTime) {
      return (
        dateTime.toLocaleDateString("da-dk", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      );
    },

    toTimeString(dateTime) {
      return (
        "kl. " +
        dateTime.toLocaleTimeString("da-dk", {
          hour: "numeric",
          minute: "numeric",
        })
      );
    },
  },
};
</script>

<style scoped>
.new-document-mark {
  color: #b50050;
  margin-left: 10px;
  font-size: 0.9rem;
}

.el-icon-loading {
  font-size: 1.5rem;
}

.pull-left {
  margin-right: 10px;
}

.text-container {
  text-align: center;
  padding: 30px;
}
</style>
